export const BaseInput = {
  inputClass:
    ' peer' +
    ' py-3' +
    ' px-4' +
    ' pr-10 ' +
    ' block' +
    ' w-full' +
    ' bg-gray-100' +
    ' border-transparent' +
    ' rounded-lg' +
    ' text-sm' +
    ' focus:border-blue-500' +
    ' focus:ring-blue-500' +
    ' disabled:opacity-50' +
    ' disabled:pointer-events-none' +
    ' appearance-none',
  labelClass: ' text-sm font-semibold inline-block mb-2.5',
  labelClassWithoutMargin: ' text-sm font-semibold',
  messagesClass: ' text-red-500' + ' text-sm' + ' pt-2.5',
  numberInputClass:
    ' peer' +
    ' py-3' +
    ' px-4' +
    ' block' +
    ' bg-gray-100' +
    ' border-transparent' +
    ' rounded-lg' +
    ' text-sm' +
    ' focus:border-blue-500' +
    ' focus:ring-blue-500' +
    ' disabled:opacity-50' +
    ' disabled:pointer-events-none' +
    ' w-1/4' +
    ' inline-block' +
    ' [&::-webkit-inner-spin-button]:opacity-100' +
    ' [&::-webkit-outer-spin-button]:opacity-100',
  suffixIconClass: 'absolute ' + 'text-gray-500 ' + 'w-5 ' + 'h-5 '
};
